import React from 'react'
import { motion } from 'framer-motion'
import { makeStyles, Container, Card, CardContent, CardHeader, Grid, Typography, CardMedia } from '@material-ui/core'
import rhinoSplash from '../img/RhinoSplash.jpg'
import logo from '../img/Logo2.jpg'
import roccoOfat from '../img/roccoOfat.jpg'

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
        display: 'flex',
        width: '100vw',
        height: '100vh',
        background: `url(${rhinoSplash})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed'
    },
    container: {
        margin: '80px auto'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    card: {
        background: theme.palette.cardBg.main
    },
}))

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 1.5,
            when: "beforeChildren"
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 1
        }
    }
}

const cardVariants = {
    hidden: {
        y: 1000
    },
    visible: {
        y: 0,
        transition: {
            duration: 1,
        }
    },
    exit: {
        x: '-100vw',
        transition: {
            ease: 'easeInOut'
        }
    }
}

export default function Conservation() {
    const classes = useStyles()

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className={classes.root}
        >
            <Container
                className={classes.container}
            >
                <motion.div
                    variants={cardVariants}
                >
                    <Card>
                        <CardHeader
                            title="Conservation"
                        />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <img src={logo} width="100%" />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography>
                                        Our passion is the conservation of all endangered species. We build custom itineraries for wildlife tourism, that are off the beaten track, and that introduce engage our travelers directly with conservation efforts at each location, for the entire duration of each Expedition. The primary purpose of these trips is to raise money for the conservation groups. 
                                    </Typography>
                                    <br/>
                                    <Typography>
                                        As you can tell from our logo, we are passionate about the Javan Rhino. Sadly, there are only roughly 60 individuals of this once widespread species of Rhino. This particular animal has become our focus. Through a long standing and fruitful partnership with the Friends of the Rhino conservation group, based in Ulung Kujong National Park in Indonesia. 
                                    </Typography>
                                    <br/>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>

                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Card>
                                                <CardMedia
                                                    className={classes.media}
                                                    image={roccoOfat}
                                                />
                                                <CardContent>
                                                    <em>Rocco and Ofat from the Friends of the Rhino Foundation</em>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </motion.div>
            </Container>
        </motion.div>
    )
}
