import React from 'react'
import { Typography, makeStyles, Button } from '@material-ui/core'
import OldPaper from '../img/OldPaper.jpg'
import splashBg from '../img/SplashPageBG.jpg'
import { motion } from 'framer-motion'
import useSound from 'use-sound';
import zhoom from '../audio/zhoom.mp3'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    card: {
        background: `url(${OldPaper})`
    },
    headerImg: {
        height: '100vh',
        background: `url(${splashBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'left'
    },
    greeting: {
        paddingTop: 250,
        color: '#fff',
        textAlign: 'center',
        textShadow: '2px 2px 3px #000'
    },
    btn: {
        color: '#fff',
        borderColor: '#fff'
    },
    btnDiv: {
        marginBottom: 10
    }
}))

const containerVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 1.5,
            when: "beforeChildren"
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 1.5
        }
    }
}

const greetingVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 1,
            when: "beforeChildren"
        }
    },
    exit: {
        x: '100vh',
        transition: {
            duration: 1.5
        }
    }
}

const btnVariants = {
    hidden: {
        y: 100,
        opacity: 0
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
        }
    },
    exit: {
        x: '100vh',
        transition: {
            duration: 1.5
        }
    }
}

export default function Home() {
    const classes = useStyles()
    const [play] = useSound(zhoom)

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className={classes.headerImg}
        >
            <motion.div 
                variants={greetingVariants}
                className={classes.greeting}
            >
                <Typography variant="h3">Embark on an Adventure</Typography>
                <Typography>
                    <b>To Protect Endangered Species...</b>
                </Typography>
                <br/><br/>
                <motion.div variants={btnVariants} className={classes.btnDiv}>
                    <Link to="/expeditions">
                        <Button onClick={play} color="primary" variant="contained" className={classes.btn}>Our Expeditions</Button>
                    </Link>
                </motion.div>
                <motion.div variants={btnVariants} className={classes.btnDiv}>
                    <Link to="/conservation">
                        <Button onClick={play} color="primary" variant="contained" className={classes.btn}>Conservation</Button>
                    </Link>
                </motion.div>
            </motion.div>
        </motion.div>
    )
}
