import { makeStyles, Container, Card, CardContent, CardHeader, TextField, Button } from '@material-ui/core'
import { motion } from 'framer-motion'
import React from 'react'
import rhino from '../img/rhino.jpg'
import { textFieldProps } from '../shared/constants'
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
        display: 'flex',
        width: '100vw',
        height: '100vh',
        background: `url(${rhino})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed'
    },
    container: {
        margin: 'auto'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    }
}))

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 1.5,
            when: "beforeChildren"
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 1
        }
    }
}

const cardVariants = {
    hidden: {
        y: 1000
    },
    visible: {
        y: 0,
        transition: {
            duration: 1,
        }
    },
    exit: {
        x: '-100vw',
        transition: {
            ease: 'easeInOut'
        }
    }
}

export default function Contact() {
    const classes = useStyles()

    const [state, setState] = React.useState({})

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

    }

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className={classes.root}
        >
            <Container
                className={classes.container}
            >
                <motion.div
                    variants={cardVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <Card>
                        <CardHeader
                            title="Get In Touch ... "
                        />
                        <CardContent>
                            <form onSubmit={handleSubmit}>
                                <TextField 
                                    {...textFieldProps}
                                    label="First Name"
                                    required
                                    onChange={handleChange}
                                    name="firstName"
                                />
                                <TextField 
                                    {...textFieldProps}
                                    label="Last Name"
                                    required
                                    onChange={handleChange}
                                    name="lastName"
                                />
                                <TextField 
                                    {...textFieldProps}
                                    label="Email"
                                    required
                                    type="email"
                                    onChange={handleChange}
                                    name="email"
                                />
                                <TextField 
                                    {...textFieldProps}
                                    label="Phone Nr. (Optional)"
                                    onChange={handleChange}
                                    name="phone"
                                />
                                <br/><br/>
                                <Button startIcon={<SendIcon />} fullWidth type="submit" disabled={ !state.country} color="primary" variant="contained">Submit</Button>
                            </form>
                        </CardContent>
                    </Card>
                </motion.div>
            </Container>
        </motion.div>
    )
}
