import { Card, CardContent, Step, Typography, Container, makeStyles, Stepper, CardHeader, StepLabel, StepContent, Dialog, DialogContent, Button, Grow, CardMedia } from '@material-ui/core'
import React, { useState } from 'react'
import walking from '../img/newBg.jpg'
import splashPage2 from '../img/SplashPage2.jpg'
import { motion } from 'framer-motion'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Grid } from '@material-ui/core'
import rhinoCam from '../img/rhinoCam.jpg'
import Itinerary from './Itinerary'
import useSound from 'use-sound';
import pageTurn from '../audio/pageturn.mp3'
import pageClose from '../audio/pageClose.mp3'
import jungleAmbience from '../audio/jungle.mp3'
import { DialogActions } from '@material-ui/core'
import { Link } from 'react-router-dom'
import camp from '../img/camp.jpg'
import roccoBoat from '../img/roccoRiver.jpg'
import riverWalking from '../img/riverWalking.jpg'
import zhoom from '../audio/zhoom.mp3'

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
        display: 'flex',
        width: '100vw',
        height: '100vh',
        background: `url(${walking})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed'
    },
    container: {
        margin: '80px auto'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    dialogContent: {
        background: `url(${splashPage2})`,
    },
    stepper: {
        background: 'rgba(0,0,0,0.7)',
        borderRadius: 10,
        color: '#fff'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    card: {
        background: theme.palette.cardBg.main
    },
    photoCard: {
        cursor: 'pointer',
        background: theme.palette.cardBg.secondary
    }
}))

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 1.5,
            when: "beforeChildren"
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 1
        }
    }
}

const cardVariants = {
    hidden: {
        y: 1000
    },
    visible: {
        y: 0,
        transition: {
            duration: 1,
            when: "beforeChildren"
        }
    },
    exit: {
        x: '-100vw',
        transition: {
            ease: 'easeInOut'
        }
    }
}

const photoCardVariants = {
    hidden: {
        y: 50,
        opacity: 0
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
        }
    },
}

const Transition = React.forwardRef(function Transition(props, ref) {
    // return <Slide direction="up" ref={ref} {...props} />;
    return <Grow ref={ref} {...props} />;
});

export default function Expeditions() {
    const classes = useStyles()
    const [play] = useSound(pageTurn)
    const [playClose] = useSound(pageClose)
    const [itinOpen, setItinOpen] = useState(false)
    const [ambiencePlay, { stop }] = useSound(jungleAmbience, { volume: 0.2 });
    const [inclusionsOpen, setInclusionsOpen] = useState(false)
    const [playZhoom] = useSound(zhoom)

    const handleItinOpen = () => {
        play()
        setItinOpen(true)
    }

    const handleItinClose = () => {
        playClose()
        setItinOpen(false)
        stop()
    }

    const hanldeInclOpen = () => {
        play()
        setInclusionsOpen(true)
    }

    const hanldeInclClose = () => {
        playClose()
        setInclusionsOpen(false)
    }

    return (
        <motion.div 
            variants={containerVariants} 
            initial="hidden"
            animate="visible"
            exit="exit"
            className={classes.root}
        >
            <Container
                className={classes.container}
            >
                <motion.div
                    variants={cardVariants}
                >
                    <Card className={classes.card}>
                        <CardHeader
                            title="Join our 2022 Javan Rhino Expedition"
                        />
                        <CardContent>
                            <Typography>
                                An intrepid expedition into the heart of one of the most remote jungles on our planet, in search of the elusive and highly endangered Javan Rhino. Trek through dense Rainforests in Ujung Kulon National Park and soak up the amazing culture of the Indonesian people in this 7 day adventure.
                            </Typography>
                            <br/>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={7}>
                                    <Typography>
                                        All our packages exist for the benefit of wildlife conservation and we have partnered with many organizations, such as FRIENDS OF RHINO in Indonesia to further wildlife preservation.
                                    </Typography>
                                    <br/>
                                    <Typography variant="h6">
                                        Javan Expedition 
                                    </Typography>
                                    <Typography>
                                        Departs: <b>6 September 2022</b><br/>
                                        10 Days<br/>
                                        10-14 People <br/>
                                        Price per person: USD 2750<br/>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                        <Card className={classes.photoCard}>
                                            <CardMedia
                                                image={rhinoCam}
                                                className={classes.media}
                                            />
                                            <CardContent>
                                                <em>A camera trap caught this wonderful photo of a Javan Rhino feeding.</em>
                                            </CardContent>
                                        </Card>
                                </Grid>
                            </Grid>
                            <br/><br/>
                            <motion.div
                                variants={photoCardVariants}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={4}>
                                        <Card className={classes.photoCard} onClick={handleItinOpen}>
                                            <CardMedia 
                                                className={classes.media}
                                                image={roccoBoat}
                                            />
                                            <CardContent>
                                                Itinerary
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Card className={classes.photoCard} onClick={hanldeInclOpen}>
                                            <CardMedia 
                                                className={classes.media}
                                                image={camp}
                                            />
                                            <CardContent>
                                                Inclusions
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Link onClick={() => playZhoom()} to="/enquire">
                                            <Card className={classes.photoCard}>
                                                <CardMedia 
                                                    className={classes.media}
                                                    image={riverWalking}
                                                />
                                                <CardContent>
                                                    Enquire Now
                                                </CardContent>
                                            </Card>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </motion.div>
                        </CardContent>
                    </Card>

                    <Dialog 
                        fullScreen 
                        open={itinOpen} 
                        onClose={() => setItinOpen(false)} 
                        TransitionComponent={Transition}
                        
                    >
                        <DialogContent className={classes.dialogContent} onMouseEnter={() => ambiencePlay()} onMouseLeave={() => stop()}>
                            <Container>
                                <Button onClick={handleItinClose} startIcon={<ArrowBackIcon />} color="primary" variant="contained">Back to Expeditions</Button>
                                <br/><br/>
                                <Itinerary />
                            </Container>
                        </DialogContent>
                    </Dialog>

                    <Dialog maxWidth="md" fullWidth open={inclusionsOpen} onClose={hanldeInclClose} TransitionComponent={Transition}>
                        <DialogContent>
                            <Typography>
                                Inclusions:<br/>
                                <ul>
                                    <li>Donation for Rhino Conservation</li>
                                    <li>National Park Entrance permit (visitor)</li>
                                    <li>SIMAKSI (special permit – activity in the core zone)</li>
                                    <li>Rent car (Shuttle)</li>
                                    <li>Accommodation 1 night in Labuan hotel</li>
                                    <li>Accommodation 1 night in Karang Ranjang</li>
                                    <li>Accommodation 1 night at hotel near airport</li>
                                    <li>Logistic (meals and drinks)</li>
                                    <li>Speed boat (pick up from Cigenter river)</li>
                                    <li>Local guide </li>
                                    <li>Porter fee </li>
                                    <li>Camping equipment</li>
                                    <li>Rhino T-shirt</li>
                                </ul>
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={hanldeInclClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </motion.div>
            </Container>
        </motion.div>
    )
}
