import { Card, CardContent, Grid, Typography, Container, makeStyles, CardMedia, Button } from '@material-ui/core'
import React from 'react'
import { motion } from 'framer-motion'
import posingLogo from '../img/posingLogo.jpeg'
import boat from '../img/boat.jpg'
import roccoVan from '../img/roccoVan.jpeg'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
        display: 'flex',
        width: '100vw',
        height: '100vh',
        background: `url(${boat})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    container: {
        margin: 'auto'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    card: {
        background: theme.palette.cardBg.main
    },
}))

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 1.5,
            when: "beforeChildren"
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 1
        }
    }
}

const cardVariants = {
    hidden: {
        y: 1000
    },
    visible: {
        y: 0,
        transition: {
            duration: 1,
        }
    },
    exit: {
        x: '-100vw',
        transition: {
            ease: 'easeInOut'
        }
    }
}



export default function About() {
    const classes = useStyles()

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className={classes.root}
        >
            <Container
                className={classes.container}
            >
                <motion.div
                    variants={cardVariants}
                >
                    <Card>
                        <CardContent>
                            <div style={{ textAlign: 'center'}}>
                                <img src={posingLogo} width={350} />
                                <br/><br/>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Typography>
                                            Our packages are adventure focused, usually to areas where a specific endangered species resides. On these trips we track and observe these magnificent animals, engage locals and educate our guests about the importance of conservation. The majority of our profits then goes right back into these communities with the sole focus of providing support to groups engaged in the conservation efforts of that specific area and species.

                                            
                                        </Typography>
                                        <br/>
                                        <Typography>
                                            The Expeditions Company invites you to join us on some of the most off the beaten path travel packages in the world. All our packages exist for the benefit of wildlife conservation and we have partnered with many organizations, such as FRIENDS OF RHINO in Indonesia to further wildlife preservation.
                                        </Typography>
                                        <br/>
                                        <Typography>
                                            The Expeditions Company was founded by Rocco Olivier.
                                        </Typography>
                                        <br/>
                                        <Link to="/conservation">
                                            <Button color="primary" variant="contained">Conservation</Button>&nbsp;
                                        </Link>
                                        <Link to="/expeditions">
                                            <Button color="primary" variant="contained">See Our Next Expedition</Button>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Card>
                                            <CardMedia
                                                className={classes.media}
                                                image={roccoVan}
                                                title="Rocco"
                                            />
                                        </Card>
                                    </Grid>
                                </Grid>
                                <br/>
                                
                            </div>
                        </CardContent>
                    </Card>
                </motion.div>
            </Container>
        </motion.div>
    )
}
