import React from 'react'
import { AppBar, Toolbar, Button, makeStyles, Grid } from '@material-ui/core/'
import menuBg from '../../img/BannerHead.jpg'
import Logo from '../../img/LogoWhiteNoText.png'
import { Link } from 'react-router-dom'
import useSound from 'use-sound';
import pageClose from '../../audio/pageClose.mp3'

const useStyles = makeStyles((theme) => ({
    appBar: {
        background: `url(${menuBg})`
    },
    btn: {
        color: '#fff',
        marginTop:10
    },
    toolBar: {
    },
    logo: {
        width: 80,   
    }
}))

export default function Header() {
    const classes = useStyles()
    const [play] = useSound(pageClose)

    return (
        <div>
            <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <Grid container justify="space-between">
                    <Grid item>
                        <Link to="/">
                            <img src={Logo} className={classes.logo}/>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link onClick={() => play()} to="/about">
                            <Button style={{ color: '#fff'}} className={classes.btn}>About</Button>
                        </Link>
                        <Link onClick={() => play()} to="/expeditions">
                            <Button style={{ color: '#fff'}} className={classes.btn}>Expeditions</Button>
                        </Link>
                        <Button style={{ color: '#fff'}} className={classes.btn}>Blog</Button>
                        <Link onClick={() => play()} to="/contact">
                            <Button style={{ color: '#fff'}} className={classes.btn}>Contact</Button>
                        </Link>
                    </Grid>
                </Grid>
                
            </Toolbar>
            </AppBar>
        </div>
    )
}
