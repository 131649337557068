import React from 'react'
import { Step, Stepper, StepLabel, StepContent, makeStyles, Typography, Grid, Card, CardMedia, Button } from '@material-ui/core'
import labuan from '../img/labuan.jpg'
import meeting from '../img/meeting.jpg'
import trekking from '../img/trekking.jpg'
import roccoRiver from '../img/roccoRiver.jpg'
import riverWalking from '../img/riverWalking.jpg'
import hotel from '../img/hotel.jpg'
import flying from '../img/flying.jpg'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    stepper: {
        background: 'rgba(0,0,0,0.8)',
        borderRadius: 10,
        color: '#fff'
    },
    label : {
        color: '#fff'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    }
}))

export default function Itinerary() {
    const classes = useStyles()
    const [activeStep, setActiveStep] = React.useState(0)

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const stepBtns = <div className={classes.actionsContainer}>
    <div>
      <Button
        disabled={activeStep === 0}
        onClick={handleBack}
        style={{ color: '#fff'}}
      >
        Back
      </Button>
      { activeStep < 6 ? 
      <Button
        variant="contained"
        color="primary"
        onClick={handleNext}
      >
           Next
      </Button>
      :
      null }
    </div>
  </div>

    return (
        <div>
            <Stepper orientation="vertical" activeStep={activeStep} className={classes.stepper}>
                <Step key={0}>
                    <StepLabel>
                        <Typography className={classes.label}>
                            Day One: Jakarta
                        </Typography>
                    </StepLabel>
                    <StepContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                We'll pick you up from Soekarno Hatta airport then embark for Labuan town, which will take 4 hours, depending on traffic. After checking in at the hotel, relax and take in the local scenery.
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card>
                                    <CardMedia
                                        image={labuan}
                                        className={classes.media}
                                    />
                                </Card>
                            </Grid>
                        </Grid>
                        { stepBtns }
                    </StepContent>
                </Step>
                <Step key={1}>
                    <StepLabel>
                        <Typography className={classes.label}>
                            Day Two: Ujung Kulon National Park
                        </Typography>
                    </StepLabel>
                    <StepContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                After breakfast 08:00 am , drive down to Ujung jaya village as the main gate of Ujung Kulon national park, it will take 4 hours , arrive at Javan Rhino Study and Conservation Area (JRSCA). Meet and greet Park ranger, local guide and porter. Lunch will be provided .
                                After lunch at 14:00 am treking to Karang Ranjang on the south coast of Ujung Kulon peninsula , a variety of wildlife including pigs, monkeys, squirrels, Banteng, etc, duration 2,5  hours walks from JRSCA. At the south coast and guard-post overlook the wave-swept sandy shore of Karang Ranjang to the Cikeusik . Dinner and Stay overnight on tent .
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card>
                                    <CardMedia
                                        image={meeting}
                                        className={classes.media}
                                    />
                                </Card>
                            </Grid>
                        </Grid>
                        { stepBtns }
                    </StepContent>
                </Step>
                <Step key={2}>
                    <StepLabel>
                        <Typography className={classes.label}>
                            Day Three: Trek to Cikeusik 
                        </Typography>
                    </StepLabel>
                    <StepContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                After breakfast at 07:30 am leave Karang Ranjang guard post toward Cikeusik, this pleasant walk provides both forest and shore experience . The trek to Cikeusik will take 5 hours . Arrive at Cikeusik beach make camp site . The Cikeusik’s river is the habitat of king fisher birds, where the crocodiles and rhinoceros may also very often be seen, dinner and overnight in tent. 
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card>
                                    <CardMedia
                                        image={trekking}
                                        className={classes.media}
                                    />
                                </Card>
                            </Grid>
                        </Grid>
                        { stepBtns }
                    </StepContent>
                </Step>
                <Step key={3}>
                    <StepLabel>
                        <Typography className={classes.label}>
                            Days Four to Seven: Explore Cikeusik River Basin.
                        </Typography>
                    </StepLabel>
                    <StepContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                Over the next few days, we'll explore the area around the Cikeusik river, tracking the elusive Javan Rhino. Spend time exploring the jungles on a canoe, or on various hiking trails. Take in the untouched natural beauty and majesty of this most pristine primary rainforest. 
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card>
                                    <CardMedia
                                        image={roccoRiver}
                                        className={classes.media}
                                    />
                                </Card>
                            </Grid>
                        </Grid>
                        { stepBtns }
                    </StepContent>
                </Step>
                <Step key={4}>
                    <StepLabel>
                        <Typography className={classes.label}>
                            Day Eight: Cigenter River.
                        </Typography>
                    </StepLabel>
                    <StepContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                After breakfast at 07:00 am , packing our stuffs and leave Cikeusik for Cigenter river through Ujung Kulon. Stay overnight at Cigenter. Dinner and recharge your stamina.
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card>
                                    <CardMedia
                                        image={riverWalking}
                                        className={classes.media}
                                    />
                                </Card>
                            </Grid>
                        </Grid>
                        { stepBtns }
                    </StepContent>
                </Step>
                <Step key={5}>
                    <StepLabel>
                        <Typography className={classes.label}>
                            Day Nine: Boat to Sumur and overland to Labuan.
                        </Typography>
                    </StepLabel>
                    <StepContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                After breakfast at 08:00 am leave Cigenter river by fast boat to tamanjaya or Sumur  and then toward Labuan – Jakarta, check in at the hotel near by Soekarno Hatta airport.
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card>
                                    <CardMedia
                                        image={hotel}
                                        className={classes.media}
                                    />
                                </Card>
                            </Grid>
                        </Grid>
                        { stepBtns }
                    </StepContent>
                </Step>
                <Step key={6}>
                    <StepLabel>
                        <Typography className={classes.label}>
                            Day Ten: Debriefing with Conservation Team and Goodbyes
                        </Typography>
                    </StepLabel>
                    <StepContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                Before your trip comes to an end, we'll discuss what knowledge we've gained through this journey and talk about all of us can make a difference for conservations in our own countries and communities. After the debrief, and a hearty farewell, you can be transported to the airport, if you wish...
                                <br/>
                                <br/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card>
                                    <CardMedia
                                        image={flying}
                                        className={classes.media}
                                    />
                                </Card>
                            </Grid>
                        </Grid>
                        { stepBtns }
                    </StepContent>
                </Step>
            </Stepper>
        </div>
    )
}
