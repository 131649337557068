import { Switch, Route, useLocation } from 'react-router-dom'
import Header from "./components/nav/Header";
import Home from "./components/Home";
import About from "./components/About";
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core'
import walking from './img/walking.jpeg'
import { AnimatePresence } from "framer-motion";
import Expeditions from "./components/Expeditions";
import Enquire from "./components/Enquire";
import Contact from "./components/Contact";
import { amber, brown, lime, grey } from '@material-ui/core/colors'
import Conservation from './components/Conservation';

const cairo =  "'Cairo', sans-serif";

const useStyles = makeStyles((theme) => ({
  body: {
    background: `url(${walking})`,
    backgroundSize: 'cover',
    backgroundPosition: 'left'
  }
}))

const expTheme = createMuiTheme({
  palette: {
    primary: {
      main: lime[900]
    },
    cardBg: {
      main: grey[100],
      secondary: amber[50]
    },
    
  },
  typography: {
    fontFamily: [
      cairo
    ].join(','),
  },
})

function App() {
  const classes = useStyles()
  const location = useLocation()
  return (
    <div className="App">
      <ThemeProvider theme={expTheme}>
        <Header />
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.key}>
            
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/expeditions" component={Expeditions} />
            <Route path="/enquire" component={Enquire} />
            <Route path="/contact" component={Contact} />
            <Route path="/conservation" component={Conservation} />
          </Switch>
        </AnimatePresence>
      </ThemeProvider>
    </div>
  );
}

export default App;
